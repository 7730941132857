import React from 'react'
import SEO from "components/seo"
import Heading from "components/Heading"
import ContentSlotOffset from "components/ContentSlotOffset"
import HeroImageFull from "components/HeroImageFull";
import FormContact from 'components/FormContact';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Interweave from 'interweave';

const ViewContact = ({pageData}) => {
  const {
    page,
    heading,
    formContact
  } = pageData;
  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--contact--hero">
        <HeroImageFull
          heroTextClassname="mobile--is-center"
          imageName="contactHeroImage"
          imageProps={{
            imgStyle: {
              objectPosition: "center top",
            },
          }}
        >
          <Heading className="is-heading">{heading}</Heading>
        </HeroImageFull>
      </div>

      <div className="max-container wrapper--contact--block-btc">
        <div className="container max-1048">
          <ContentSlotOffset
            className="content-slot--contact-info"
            offsetRight={true}
            image={'pageContactImage1'}
            heading="Betty Tran Consultants"
          >
            <ul className="contact-social-links">
              <li className="contact-social--link">
                <a
                  href={"https://www.bettytranconsultants.com"}
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  className="social"
                >
                  <div className="social-icon">
                    <FontAwesomeIcon
                      style={{ fontSize: "24px" }}
                      icon={["fas", "globe"]}
                    />
                  </div>
                  <span>www.bettytranconsultants.com</span>
                </a>
              </li>
              <li className="contact-social--link">
                <a
                  href={"https://www.facebook.com/bettytranconsultants"}
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  className="social"
                >
                  <div className="social-icon">
                    <FontAwesomeIcon
                      style={{ fontSize: "24px" }}
                      icon={["fab", "facebook-f"]}
                    />
                  </div>
                  <span>facebook.com/bettytranconsultants</span>
                </a>
              </li>
              <li className="contact-social--link">
                <a
                  href={"https://www.instagram.com/bettytranconsultants"}
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  className="social"
                >
                  <div className="social-icon">
                    <FontAwesomeIcon
                      style={{ fontSize: "24px" }}
                      icon={["fab", "instagram"]}
                    />
                  </div>
                  <span>instagram.com/bettytranconsultants</span>
                </a>
              </li>
            </ul>
          </ContentSlotOffset>
        </div>
      </div>

      <div className="max-container wrapper--contact--block-form">
        <div className="container max-1048">
          <Heading className="">
            <Interweave content={formContact.text} />
          </Heading>
          <ContentSlotOffset
            className="content-slot--contact-form"
            offsetLeft={true}
            image={'pageContactImage2'}
            heading=""
          >
            <FormContact pageData={pageData} />
          </ContentSlotOffset>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewContact