import { useStaticQuery, graphql } from "gatsby"

const usePageData = () => {
  const data = useStaticQuery(graphql`
    {
      allDataYaml(filter: { codePage: { eq: "contact-page" } }) {
        edges {
          node {
            ...fragmentContactPage
          }
        }
      }
    }
  `)

  if (!data) return null

  return data.allDataYaml.edges[0].node
}

export default usePageData
