import React, { useState, useEffect } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { trimAll } from 'utils/utils';
import { motion, AnimatePresence } from "framer-motion"
import { fadeVariants } from 'utils/motion.utils';
import GSpinner from 'components/GSpinner';

const reactBinaryAxios = axios.create({
  headers: {
    "Content-Type": "multipart/form-data",
  },
})

const FormContact = ({ pageData, className, ...props }) => {
  const { formContact } = pageData;
  const [isSent, setSent] = useState(false);
  const { register, handleSubmit, formState, watch, reset } = useForm({
    mode: 'onBlur'
  });
  const watchData = watch(["email","fullName","location","message"]);

  const { isDirty, isSubmitting } = formState;

  const onSubmit = data => {
    const API = `https://bettytranconsultants.com/mailer/contact.php`

    const formFileData = new FormData()
    formFileData.append("fullName", data.fullName)
    formFileData.append("location", data.location)
    formFileData.append("email", data.email)
    formFileData.append("message", data.message)

    reactBinaryAxios({
      url: API,
      method: "post",
      data: formFileData,
    })
      .then(function (response) {
        reset();
        setSent(true);
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  useEffect(() => {
    const shouldRemove = Boolean(
      isSent && (
        trimAll(watchData.fullName) !== '' ||
        trimAll(watchData.location) !== '' ||
        trimAll(watchData.email) !== '' ||
        trimAll(watchData.message) !== ''
      )
    )

    if (shouldRemove) {
      setSent(false);
    }
  }, [watchData, isSent]);

  useEffect(() => {
    if (isSent) {
      setTimeout(() => setSent(false), 5000)
    }
  }, [isSent]);

  return (
    <div className="form-body form-about-contact">
      <AnimatePresence>
        {isSubmitting && (
          <motion.div
            initial="hidden"
            variants={fadeVariants}
            animate={'visible'}
            exit="hidden"
            className={`wrapper--gspinner`}>
            <GSpinner className="spinner" />
          </motion.div>
        )}
      </AnimatePresence>
      <form method={"POST"} onSubmit={handleSubmit(onSubmit)}>
        <AnimatePresence>
          {isSent && (
            <motion.div
              variants={fadeVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className="notification is-info is-contact">
              {formContact.sentSuccess}
            </motion.div>
          )}
        </AnimatePresence>
        <div className="field is-horizontal">
          <div className="field-label is-center">
            <label htmlFor="contact--full-name" className="label">
              {formContact.fieldFullname} <span className="color--red">*</span>
            </label>
          </div>
          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <p className="control is-expanded">
                  <input
                    aria-label={formContact.fieldFullname}
                    id="contact--full-name"
                    className="input"
                    type="text"
                    placeholder=""
                    name="fullName"
                    ref={register({ required: true, maxLength: 128 })}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-center">
            <label htmlFor="contact--email" className="label">
              {formContact.fieldEmail} <span className="color--red">*</span>
            </label>
          </div>
          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <p className="control is-expanded">
                  <input
                    aria-label={formContact.fieldEmail}
                    id="contact--email"
                    className="input"
                    type="email"
                    placeholder=""
                    name="email"
                    ref={register({ required: true, maxLength: 128 })}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-center">
            <label htmlFor="contact--location" className="label">
              {formContact.fieldLocation}
            </label>
          </div>
          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <p className="control is-expanded">
                  <input
                    aria-label={formContact.fieldLocation}
                    id="contact--location"
                    className="input"
                    type="text"
                    placeholder=""
                    name="location"
                    ref={register({ required: false, maxLength: 128 })}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label htmlFor="contact--message" className="label">
              {formContact.fieldMessage}
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <textarea
                  id="contact--message"
                  aria-label={formContact.fieldMessage}
                  className="textarea"
                  placeholder=""
                  name="message"
                  ref={register({ required: false })}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal"></div>
          <div className="field-body">
            <div className="required-text">* {formContact.fieldRequired}</div>
          </div>
        </div>
        <div className="field field-submit">
          <button
            disabled={!isDirty || isSubmitting}
            type="submit"
            className="btn--send btn--outline-2"
          >
            {formContact.btnSend}
          </button>
        </div>
      </form>
    </div>
  )
}

FormContact.defaultProps = {
  className: "",
}

export default FormContact
